(function ($) {
  $(function () {
    // Set theme from localStorage on page load
    const themeType = localStorage.getItem("chatenai-theme");

    // Apply theme based on localStorage value
    if (themeType === "dark") {
      $("body").addClass("active-light-mode"); // 'dark' theme applies 'active-light-mode'
      $("#my_switcher .setColor.dark").addClass("active");
      $("#my_switcher .setColor.light").removeClass("active");
    } else {
      $("body").removeClass("active-light-mode"); // 'light' theme removes 'active-light-mode'
      $("#my_switcher .setColor.light").addClass("active");
      $("#my_switcher .setColor.dark").removeClass("active");
    }

    // Function to toggle theme and update button state
    const toggleTheme = (selectedTheme) => {
      if (selectedTheme === "dark") {
        $("body").addClass("active-light-mode");
        localStorage.setItem("chatenai-theme", "dark");
        $("#my_switcher .setColor.dark").addClass("active");
        $("#my_switcher .setColor.light").removeClass("active");
      } else {
        $("body").removeClass("active-light-mode");
        localStorage.setItem("chatenai-theme", "light");
        $("#my_switcher .setColor.light").addClass("active");
        $("#my_switcher .setColor.dark").removeClass("active");
      }
    };

    // Set up click events for the theme switcher buttons
    $("#my_switcher .setColor").on("click", function () {
      const selectedTheme = $(this).hasClass("dark") ? "dark" : "light";
      toggleTheme(selectedTheme); // Toggle theme based on the clicked button
    });
  });
})(jQuery);
